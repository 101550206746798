var mypageEdit = (function ($) {

    var form = (function () {
        var $modal = $('.modal');
        var $title = $modal.find('h2');
        var $btn = $modal.find('.btn span');

        var oneself_nickName = personalData2.oneself[0].nickname,
            oneself_firstName = personalData2.oneself[0].firstName,
            oneself_lastName = personalData2.oneself[0].lastName,
            oneself_firstNameKana = personalData2.oneself[0].firstNameKana,
            oneself_lastNameKana = personalData2.oneself[0].lastNameKana,
            oneself_gender = personalData2.oneself[0].gender,
            oneself_birthDate = personalData2.oneself[0].birthDate;

        if(oneself_nickName && oneself_firstName && oneself_lastName && oneself_firstNameKana && oneself_lastNameKana && oneself_gender && oneself_birthDate){
            isErrorMessage = false;
        }else{
            isErrorMessage = true;
        }

        //var activeTarget = userProfile.profileType.toLowerCase();
        var activeTarget = 'oneself';

        var activeNum = profileSeqArtNum();

        function profileSeqArtNum() {
            for (var i = 0; i < personalData2.partner.length; i++) {
                if (personalData2.partner[i].profileSeq === userProfile.profileSeq) {
                    return i;
                }
            }
        }

        function returnUrl() {
            if (activeTarget === 'oneself') {
                var url = '/fortune/daily';
            } else if (activeTarget === 'partner' && typeof activeNum === "number") {
                var url = '/profile/partner/update';
            } else {
                var url = '/profile/partner/add';
            }
            return url;
        }

        //modal内btnのclick
        //基本的にはupdateのはず
        $btn.on('click', function () {
            //isErrorMessage = false;
            formValidation();
            return false;
        });

        //日運を開くclick
        $('[data-modal]').on('click', function () {
            var $this = $(this);
            var $dataModal = $this.data('modal');
            var $dataNum = $this.data('num');

            activeTarget = $dataModal;
            activeNum = $dataNum;

            $modal.find('[class*=errorMsg_]').text('');

            if ($dataNum) {
                objectToInput($dataModal, $dataNum);
            } else {
                objectToInputDelete();
            }
            //入力不足のフォームがあればmodalを表示するし、なければ日運へ
            if (isErrorMessage) {
                modalOpen($dataModal, $dataNum);
            } else {
                profileSubmit(returnUrl(), getInputData());
            }
            return false;
        });

        $('.close').on('click', function () {
            modalClose();
            //isErrorMessage = false;
            return false;
        });

        var modalOpen = function (target, num) {
            $btn.text(num ? '更新' : '登録する');
            $title.text(target === 'oneself' ? 'あなたのプロフィールを編集' : '相手のプロフィールを編集');
            var $nickname = $modal.find('.nickname, .errorMsg_nickname');
            var $name = $modal.find('.name');
            var $birthDate = $modal.find('.birthDate');

            if (target === 'partner') {
                $nickname.addClass('off');
                $name.find('.required').removeClass('off');
                $birthDate.find('.required').addClass('off');
            } else {
                $nickname.removeClass('off');
                //$name.find('.required').addClass('off');
                $birthDate.find('.required').removeClass('off');
            }

            $('.js-site').addClass('off');
            $modal.removeClass('off');
            $(window).scrollTop(0);
        };

        var modalClose = function () {
            $('.js-site').removeClass('off');
            $modal.addClass('off');
        };

        var objectToInputDelete = function () {
            var $edit = $('.edit [name]');

            $edit.each(function (i) {
                var $this = $(this);
                var name = $this.prop('name');

                if (name === 'gender') {
                    if ($this.val() === 'MALE') {
                        $this.prop('checked', true);
                    } else {
                        $this.prop('checked', false);
                    }
                } else if (name === 'birthDate') {
                    $this.val('');
                } else if (name === 'bloodType') {
                    $this.find('option').each(function (i) {
                        var $option = $(this);
                        $option.prop('selected', false);
                    });
                } else {
                    $this.val('');
                }
            });
        };

        var objectToInput = function (target, num) {
            var data = num ? personalData2[target][num - 1] : personalData2[target];
            var $edit = $('.edit [name]');

            $edit.each(function (i) {
                var $this = $(this);
                var name = $this.prop('name');

                if (name === 'gender') {
                    if ($this.val() === data[name]) {
                        $this.prop('checked', true);
                    }
                } else if (name === 'birthDate') {
                    $this.val(data[name]);
                    var $birthDate = $this.parent();
                    if ($birthDate.size() > 0) {
                        var dataAry = data[name].split('-');
                        $birthDate.find('option').prop('selected', false);
                        $birthDate.find('.selectYear [value="' + dataAry[0] + '"]').prop('selected', true);
                        $birthDate.find('.selectMonth [value="' + dataAry[1] + '"]').prop('selected', true);
                        $birthDate.find('.selectDay [value="' + dataAry[2] + '"]').prop('selected', true);
                    }
                } else if (name === 'bloodType') {
                    $this.find('option').each(function (i) {
                        var $option = $(this);
                        if ($option.val() === data[name]) {
                            $option.prop('selected', true);
                        }
                    });
                } else {
                    $this.val(data[name]);
                }
            });
        };

        var getInputData = function () {
            var data = {};
            var $edit = $('.edit [name]');

            if (activeNum) {
                var globalPersonalData = personalData2[activeTarget][activeNum - 1];
            }

            $edit.each(function (i) {
                var $this = $(this);
                var name = $this.prop('name');

                if (name === 'gender') {
                    if ($this.prop('checked')) {
                        data[name] = $this.val();
                    }
                } else if (name === 'bloodType') {
                    $this.find('option').each(function (i) {
                        var $option = $(this);
                        if ($option.prop('selected')) {
                            data[name] = $option.val();
                        }
                    });
                } else {
                    data[name] = $this.val();
                }
            });

            if (globalPersonalData) {
                data.id = globalPersonalData.id;
                data.profileType = globalPersonalData.profileType;
                data.profileSeq = globalPersonalData.profileSeq;
            } else if (isErrorMessage) {
                data.id = userProfile.id;
                data.profileType = userProfile.profileType;
                data.profileSeq = userProfile.profileSeq;
            } else {
                data.profileType = 'PARTNER';
            }

            return (data);
        };

        var formValidation = function () {
            var validation = function (target, items) {
                var hasError = false;
                for (var i = 0; i < items.length; i++) {
                    hasError = EditStore[target + '_' + items[i]] && !hasError ? false : true;
                }
                return hasError;
            };

            var validationDate = function (target) {
                var birthDate = new Date(target).getTime();
                return birthDate > new Date().getTime();
            };

            //入力formへ値が入っているかを確認する。なければalertを表示
            //全て入力されていればsubmitへ
            var targetCheck = function (isOneself, isPartner) {
                if (isOneself) {
                    var nicknameVal = $('#nickname').val();
                    if (!nicknameVal) {
                        $('.editNickNameContent').find('.error').removeClass('off');
                    }

                    var lastName = $('#lastName').val();
                    if (!lastName) {
                        $('.editNameContent').find('.error').removeClass('off');
                    }

                    var firstName = $('#firstName').val();
                    if (!firstName) {
                        $('.editNameContent').find('.error').removeClass('off');
                    }

                    var lastNameKana = $('#lastNameKana').val();
                    if (!lastNameKana) {
                        $('.editNameKanaContent').find('.error').removeClass('off');
                    }

                    var firstNameKana = $('#firstNameKana').val();
                    if (!firstNameKana) {
                        $('.editNameKanaContent').find('.error').removeClass('off');
                    }

                    var birthdate = $('.birthdateInput').val();
                    if (!birthdate) {
                        $('.editBirthDateContent').find('.error').removeClass('off');
                    } else {
                        validationDate(birthdate);
                    }

                    var genderMan = $('#genderMan').prop('checked');
                    var genderWoman = $('#genderWoman').prop('checked');
                    if (!genderMan && !genderWoman) {
                        $('.editGenderContent').find('.error').removeClass('off');
                    }
                    if (nicknameVal && lastName && firstName && lastNameKana && firstNameKana && birthdate) {
                        isErrorMessage = false;
                        profileUpdateSubmit(returnUrl(), getInputData());
                    }
                    //TODO
                    var bloodType = $('#editBloodType').val();
                    if (bloodType === '') {
                        $('.editBloodType').find('.error').removeClass('off');
                    }
                }
            };

            targetCheck(true, false);
        };

        //ユーザー情報をupdateするかどうかで分けたけれど1つで良いかも
        //サーバー側と値を詰める変数名を揃えないと上手くいかないので注意
        var postCount = 0;
        var profileSubmit = function (url, data) {
            var $form = $('<form />', {action: url, method: 'post'})
                .append($('<input />', {type: 'hidden', name: 'xtoken', value: xtoken}))
                .append($('<input />', {type: 'hidden', name: 'satoriUserId', value: userId}))

                .append($('<input />', {type: 'hidden', name: 'nickname', value: data.nickname}))
                .append($('<input />', {type: 'hidden', name: 'firstName', value: data.firstName}))
                .append($('<input />', {type: 'hidden', name: 'lastName', value: data.lastName}))
                .append($('<input />', {type: 'hidden', name: 'firstNameKana', value: data.firstNameKana}))
                .append($('<input />', {type: 'hidden', name: 'lastNameKana', value: data.lastNameKana}))
                .append($('<input />', {type: 'hidden', name: 'gender', value: data.gender}))
                .append($('<input />', {type: 'hidden', name: 'birthday', value: data.birthDate}))
                .append($('<input />', {type: 'hidden', name: 'bloodType', value: data.bloodType}))
                .append($('<input />', {type: 'hidden', name: 'isChange', value: true}))

                .append($('<input />', {type: 'hidden', name: 'favorite', value: true}));


            if (data.id) {
                $form.append($('<input />', {type: 'hidden', name: 'id', value: data.id}));
            }
            if (data.profileType) {
                $form.append($('<input />', {type: 'hidden', name: 'profileType', value: data.profileType}));
            }
            if (data.profileSeq) {
                $form.append($('<input />', {type: 'hidden', name: 'profileSeq', value: data.profileSeq}));
            }

            $form.appendTo(document.body).submit();

            postCount++;

        };

        var profileUpdateSubmit = function (url, data) {
            //if (postCount === 0) {
            var $form = $('<form />', {action: url, method: 'post'})
                .append($('<input />', {type: 'hidden', name: 'xtoken', value: xtoken}))
                .append($('<input />', {type: 'hidden', name: 'satoriUserId', value: userId}))

                .append($('<input />', {type: 'hidden', name: 'nickname', value: data.nickname}))
                .append($('<input />', {type: 'hidden', name: 'firstName', value: data.firstName}))
                .append($('<input />', {type: 'hidden', name: 'lastName', value: data.lastName}))
                .append($('<input />', {type: 'hidden', name: 'firstNameKana', value: data.firstNameKana}))
                .append($('<input />', {type: 'hidden', name: 'lastNameKana', value: data.lastNameKana}))
                .append($('<input />', {type: 'hidden', name: 'gender', value: data.gender}))
                .append($('<input />', {type: 'hidden', name: 'birthday', value: data.birthDate}))
                .append($('<input />', {type: 'hidden', name: 'bloodType', value: data.bloodType}))
                .append($('<input />', {type: 'hidden', name: 'isChange', value: true}))

                .append($('<input />', {type: 'hidden', name: 'favorite', value: true}));


            if (data.id) {
                $form.append($('<input />', {type: 'hidden', name: 'id', value: data.id}));
            }
            if (data.profileType) {
                $form.append($('<input />', {type: 'hidden', name: 'profileType', value: data.profileType}));
            }
            if (data.profileSeq) {
                $form.append($('<input />', {type: 'hidden', name: 'profileSeq', value: data.profileSeq}));
            }

            $form.appendTo(document.body).submit();


            postCount++;
        };
    })();

    //Android用、日付入力フォームの値が変更されたら適用する。
    $('.selectYear, .selectMonth, .selectDay').on('change', function () {
        var $birthday = $(this).parent(),
            year = $birthday.find('.selectYear').val(),
            month = $birthday.find('.selectMonth').val(),
            day = $birthday.find('.selectDay').val(),
            birthday = year + '-' + month + '-' + day;

        $birthday.find('input').val(birthday).trigger('change');
    }).trigger('change');


    var formValidationName = function (validationType, text) {
        var matchKanji = /[\u4E00-\u9FCF|\u3041-\u309F|々]*/;
        var matchKana = /[\u3041-\u309F]*/;

        var type = validationType.replace('', '').replace('partner_', '');
        var isKanji = type === 'firstName' || type === 'lastName';
        var matchType = isKanji ? matchKanji : matchKana;

        var n = "";
        var s = text;
        if (isKanji) {
            if (s.length > 5) {
                s = s.substring(0, 5);
                $(this).val(s);
            }
        }
        for (var c in s) {
            n += s[c].match(matchType);
            if (n.length == 8) {
                break;
            }
        }
        if (n == "") {
            for (var i = 1; i < 9; i++) {
                n += s.substring(([i] - 1), [i]).match(matchType);
            }
        }
        $('#' + validationType).val(n);
    };

    $(document).on('change', '#firstName,#lastName,#firstNameKana,#lastNameKana', function () {
        formValidationName($(this).prop('id'), $(this).val());
    });
})(jQuery);

